import React from "react";
import { Switch, Router, Route } from "react-router-dom";
import history from "./helpers/history";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import 'babel-polyfill'
// Pages
import IndexPage from "./pages/index";
import Schedule from "./pages/schedule";


const options = {
	autoConfig: true,
	debug: true,
  };
ReactPixel.init('1053223405536700', {}, options);
ReactGA.initialize('UA-154594141-1');
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
	return (
		<Router history={history}>
			<Switch>
				<Route exact path="/" component={IndexPage}></Route>
				<Route exact path="/agenda" component={Schedule}></Route>
			</Switch>
		</Router>
	);
}

export default App;
