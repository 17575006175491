import { useTranslation } from "react-i18next";
import React, { useRef } from "react";
import { Grid, Slide, Button } from "@material-ui/core";
import Lottie from "react-lottie";
import animationData from "../../assets/json/team-work.json";
import animationDataArrow from "../../assets/json/arrow-jump-down-lottie.json";

import useOnScreen from '../../helpers/useOnScreen'
import { scroller } from "react-scroll";
const ImpressionThree = () => {
    const { t } = useTranslation('common');
    const ref = useRef()
    const isVisible = useOnScreen(ref)

    const scrollToNext = () => {
        scroller.scrollTo("lastPage", {
            duration: 900,
            delay: 0,
            smooth: "easeInBack",
        });
    }
    const downArrowOptions = {
        loop: true,
        autoplay: true,
        animationData: animationDataArrow,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    const teamWorkOptions = {
        loop: true,
        autoplay: true,
        speed: 2.5,

        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <div ref={ref} width="100%" id="impressionThree" className="impressionTwo">
            <Grid container className="impressionTwo__grid-container">
            <Grid item={true}  xs={12} sm={12} className="AutoSteps__container">
                    <Slide direction="left" in={isVisible} mountOnEnter unmountOnExit timeout={500}>

                        <Lottie
                            options={teamWorkOptions}
                            height={300}
                            width={300} />
                    </Slide>
                    <Slide direction="right" in={isVisible} mountOnEnter unmountOnExit timeout={500}>

                        <p className="mainText">
                            {t('home.impressionThree.mainText')}
                        </p>
                    </Slide>

                </Grid>
                <Grid item={true}  xs={12} sm={12} style={{ position: 'relative' }}>
                    <Button
                        className="see-more__button active impressionOne"
                        onClick={scrollToNext}
                    >
                        <Lottie
                            options={downArrowOptions}
                            height={100} />
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}
export default ImpressionThree;
