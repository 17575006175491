import { useTranslation } from "react-i18next";
import React, { useRef, useState } from "react";
import { Grid, Slide, Typography, Tooltip, Snackbar, Chip } from "@material-ui/core";
import animationDataReview from "../../assets/json/reviews.json";
import Lottie from "react-lottie";
import useOnScreen from '../../helpers/useOnScreen'
import imgUrl from "../../assets/imgs/braloy-logo-1592933882.jpg"
import { PopupWidget } from "react-calendly";
const ImpressionFour = () => {
    const { t } = useTranslation('common');
    const ref = useRef(null)
    const isVisible = useOnScreen(ref)
    const [open, setOpen] = useState(false);

    const reviewsAnimation = {
        loop: true,
        autoplay: true,
        animationData: animationDataReview,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    const handleClickCopy = (type, value) => {
        if (type === "Whatsapp") {
            window.open(
                `https://wa.me/${value}`,
                '_blank'
            )

        } else {
            setOpen(true)
            navigator.clipboard.writeText(value)
        }


    }
    const handleClose = () => setOpen(false)
    return (
        <div ref={ref} width="100%" id="impressionFour" className="impressionFour">
            <Grid container className="impressionFour__grid-container">
                <Grid item={true} xs={12} sm={12} className="AutoSteps__container" >
                    <h3 style={{ color: 'rgb(48 25 90)' }}>{t('home.impressionFour.title')}</h3>

                    <Grid gap={10} container className="impressionFour__grid-container">

                        <Grid item={true} xs={12} sm={6} className="AutoSteps__container" >
                            <div style={{ textAlign: "justify", backgroundColor: "#fff", borderRadius: 5, padding: 28, margin: 10, minHeight: 380, shadow: true }}>
                                <div style={{ textAlign: "center", width: "100%" }}>
                                    <a href="https://braloy.cl" >
                                        <img src={imgUrl} alt="Logo Braloy" />
                                    </a>
                                </div>
                                <br />
                                <br />
                                <Typography align="left" >
                                    {t('home.impressionFour.reviewOneBraloy')}
                                    <br />
                                    <br />
                                    {t('home.impressionFour.reviewTwoBraloy')}

                                </Typography>
                            </div>
                        </Grid>
                        <Grid item={true} xs={false} sm={6} className="AutoSteps__container hideMobile">
                            <Lottie
                                options={reviewsAnimation}
                                style={{ marginLeft: 60, marginBottom: -40 }}

                            />
                        </Grid>
                        <Grid item={true} xs={12} sm={12}>
                            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                                <Chip label={t("home.impressionFour.ChipLabel")} />
                            </Snackbar>
                            <Slide direction="right" in={isVisible} mountOnEnter unmountOnExit timeout={500}>
                                <div style={{ minWidth: "100%", textAlign: "center" }} id="lastPage">

                                    <div className="contactOptions">
                                        <div onClick={() => handleClickCopy('Email', 'contacto@jetdigital.cl')} style={{ cursor: "pointer" }}>

                                            <Tooltip title={t('home.impressionFour.tooltipEmail')} placement="left">

                                                <h2>contacto@jetdigital.cl</h2>


                                            </Tooltip>
                                        </div>
                                        <div onClick={() => handleClickCopy('Whatsapp', '+56951259641')}>

                                            <Tooltip title={t('home.impressionFour.tooltipWhatsapp')} placement="right" style={{ cursor: "pointer" }}>

                                                <h2>+569 5125 9641</h2>

                                            </Tooltip>
                                        </div>
                                            <PopupWidget url='https://calendly.com/javier-jetdigital/30min' text={t('home.impressionFour.schedule')}/>
                                    </div>
                                </div>
                            </Slide>
                        </Grid>
                    </Grid>


                </Grid>
            </Grid>
        </div>
    )
}
export default ImpressionFour;
