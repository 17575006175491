import { CardMedia } from "@material-ui/core";
import { InlineWidget } from "react-calendly";
import logo from "../assets/imgs/logo.png"
const Schedule = () => {

    return <div className="schedule__Container">
        <CardMedia
            className="schedule__Logo"
            image={logo}
            component="img"
        ></CardMedia>
        <InlineWidget url="https://calendly.com/javier-jetdigital/30min" />

    </div>
}
export default Schedule