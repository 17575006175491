import { CardMedia } from "@material-ui/core";
import logoUrl from "../assets/imgs/logo.png"
import logoWhiteUrl from "../assets/imgs/logo_white_txt.png";

import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";

const MainLogo = () => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("xs"));
	return (
		<CardMedia
			className="main__Logo"
			image={mobile ? logoUrl : logoWhiteUrl}
			component="img"
		></CardMedia>
	);
};
export default MainLogo;
