import React, { useState, Suspense } from "react";
import Button from "@material-ui/core/Button";
import Typist from "react-typist";
import Lottie from "react-lottie";
import { scroller } from "react-scroll";
import animationData from "../../assets/json/down-arrow.json";
import { useTranslation } from "react-i18next";

const TypingTitle = () => {
	const [hiddenBtn, setHiddenBtn] = useState(true);
	const { t } = useTranslation('common');
	const showLogo = () => setHiddenBtn(false);
	const downArrowOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};
	const scrollToNext = () => scroller.scrollTo("impressionOne", {
		duration: 900,
		delay: 0,
		smooth: "easeOutBack",
	});

	return (
		<Suspense fallback="loading">
			<Typist
				className="video-title"
				onTypingDone={showLogo}
				cursor={{
					blink: true,
					element: " ▮",
					hideWhenDone: false,
				}}
			>
				<Typist.Delay ms={350} />
				{t('home.video.line_one')}
				<Typist.Backspace count={7} delay={350} />
				{t('home.video.line_two')}
				<Typist.Backspace count={4} delay={330} />
				{t('home.video.line_three')}
				<Typist.Delay ms={350} />
				{t('home.video.line_four')}

			</Typist>
			<Button
				className={
					hiddenBtn ? "see-more__button" : "see-more__button active"
				}
				onClick={scrollToNext}

			>
				<Lottie
					style={{ marginTop: '-20px' }}
					options={downArrowOptions}
					height={100} />
			</Button>
		</Suspense>
	);
};

export default TypingTitle;
