import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./scss/global.scss";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
//import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
//import store from "./store/store";
import common_es from "./translations/es/common.json";
import common_en from "./translations/en/common.json";
import common_pt from "./translations/pt/common.json";

    i18next.init({
        interpolation: { escapeValue: false },
        lng: 'es',
        resources: {
            en: {
                common: common_en               
            },
            es: {
                common: common_es
            },
            pt: {
                common: common_pt
            },
        },
    });
ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18next}>
			<App />
		</I18nextProvider>
		{/* <Provider store={store}>
		</Provider> */}
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
