import React from "react";
import imgUrl from "../../assets/imgs/team-jet-digital.jpg";
import TypingTitle from "./typingTitle";

const BigVideo = () => {
    
	return (<>
		<div className="main__Image">
            <div className="child__Image" style={{ backgroundImage: `url(${imgUrl})` }}></div>
        </div>
        <TypingTitle/>
        </>
	);
};

export default BigVideo;
