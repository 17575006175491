import React, { useRef } from "react";
import { Grid, Slide, Fade, Button, useTheme } from "@material-ui/core";
import Lottie from "react-lottie";
import animationData from "../../assets/json/connected-jet-digital.json";
import useOnScreen from '../../helpers/useOnScreen'
import { useTranslation } from "react-i18next";
import { scroller } from "react-scroll";


import animationDataArrow from "../../assets/json/arrow-jump-down-lottie.json";

const ImpressionOne = () => {
    const ref = useRef()
    const isVisible = useOnScreen(ref)
    const theme = useTheme();
    const connectedOptions = {
        loop: true,
        autoplay: true,
        speed: 2.5,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    const { t } = useTranslation('common');
    const scrollToNext = () => {
        scroller.scrollTo("impressionThree", {
            duration: 900,
            delay: 0,
            smooth: "easeInBack",
        });
    }
    const downArrowOptions = {
        loop: true,
        autoplay: true,
        animationData: animationDataArrow,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <div ref={ref} width="100%" id="impressionOne" className="impressionOne">
            <Grid container className="impressionOne__grid-container">
                <Grid item={true} xs={12} sm={4}>
                    <Slide direction="right" in={isVisible} mountOnEnter unmountOnExit timeout={500}>

                        <Lottie
                            options={connectedOptions}
                            style={{ paddingTop: 20 }}
                            height={theme.breakpoints.only('xs') ? 250 : 300}
                        />
                    </Slide>
                </Grid>
                <Slide direction="left" in={isVisible} mountOnEnter unmountOnExit timeout={500}>
                    <Grid item={true} xs={12} sm={8} className="impressionOne__grid--lines">

                        <Fade in={isVisible} mountOnEnter unmountOnExit timeout={1800}>
                            <h1 className="impressionText"><span>l </span>{t('home.impressionOne.line_one')}</h1>
                        </Fade>
                        <Fade in={isVisible} mountOnEnter unmountOnExit timeout={2400}>
                            <h1 className="impressionText"><span>l </span>{t('home.impressionOne.line_two')}</h1>
                        </Fade>
                        <Fade in={isVisible} mountOnEnter unmountOnExit timeout={2800}>
                            <h1 className="impressionText"><span>l </span>{t('home.impressionOne.line_three')}</h1>
                        </Fade>

                    </Grid>
                </Slide>
                <Slide direction="up" in={isVisible} mountOnEnter unmountOnExit timeout={500}>
                    <Grid item={true} xs={12} sm={12} style={{ position: 'relative' }}>

                        <Button
                            className="see-more__button active impressionOne"
                            onClick={scrollToNext}
                        >
                            <Lottie
                                options={downArrowOptions}
                                height={100} />
                        </Button>
                    </Grid>
                </Slide>
            </Grid>
        </div>
    );
};

export default ImpressionOne;
