import { Button } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const LanguageOptions = () => {
    const [,i18n] = useTranslation('common');
    const [activeLanguage, setActiveLanguage] = useState('es')
  
    return (<>
        <Button 
            onClick={() => {
                i18n.changeLanguage('en')
                setActiveLanguage('en')
            }} 
            className={activeLanguage === 'es' ? 'activeLanBtn' : 'inactiveLanBtn'}>
            <strong>ES</strong>
         </Button>
         <Button 
            onClick={() => {
                i18n.changeLanguage('pt')
                setActiveLanguage('pt')
            }} 
            className={activeLanguage === 'en' ? 'activeLanBtn' : 'inactiveLanBtn'}>
            <strong>EN</strong>

         </Button>
         <Button 
            onClick={() => {
                i18n.changeLanguage('es')
                setActiveLanguage('es')
            }} 
            className={activeLanguage === 'pt' ? 'activeLanBtn' : 'inactiveLanBtn'}>
            <strong>PT</strong>

         </Button>
    </>
    );
};
export default LanguageOptions;
