import React from "react";
import { Grid } from "@material-ui/core";
import BigVideo from "../components/video/bigVideo";
import MainLogo from "../components/mainLogo";
import ImpressionOne from "../components/impressions/impressionOne"
import ImpressionThree from "../components/impressions/impressionThree"
import ImpressionFour from "../components/impressions/impressionFour"
import LanguageOptions from "../components/languageButton";

const IndexPage = () => {
	return (<>
		<Grid container className="first-impression-container">
			<MainLogo/>
			<LanguageOptions/>
			<BigVideo />
		</Grid>
		<ImpressionOne/>
		<ImpressionThree/>
		<ImpressionFour/>
		</>
	);
};

export default IndexPage;
